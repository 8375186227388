import React from 'react'
import { filterItemsByApiKey } from '../../utils/common';
import ReactMarkdown from 'react-markdown';

export default function SupportedIntegration({ integration }) {
    if (!integration) {
        return "";
    }
    let summary = filterItemsByApiKey(integration, "main_summary", true)
    let logos = filterItemsByApiKey(integration, "name_photo_and_background", false);

    return (
        <section class="white-section">
            <div class="container">
                <div class="content-center">
                    {summary &&
                        <React.Fragment>
                            <h2 class="h2">{summary.title}</h2>
                            <div class="sm-spacing"></div>
                            <p class="p center-align">
                                <ReactMarkdown source={summary.description} escapeHtml={false}></ReactMarkdown>
                            </p>
                            <div class="md-spacing"></div>
                        </React.Fragment>
                    }

                    <div class="grid-container-integration">
                        {logos.map(logo => {
                            return <div class="content-left">
                                <div class="card-wrapper">
                                    <div class="integration-color" style={{
                                        backgroundImage: `url(${logo.photo && logo.photo.url})`,
                                        backgroundColor: logo.backgroundColor && logo.backgroundColor.hex,
                                        backgroundPosition: "50% 50%",
                                        backgroundSize: "auto",
                                        backgroundRepeat: "no-repeat"
                                    }}></div>
                                </div>
                                <h6>{logo.name}</h6>
                            </div>
                        })}
                    </div>
                </div>
            </div>
        </section>
    )
}
