import React from 'react'
import ReactMarkdown from "react-markdown";
import { filterItemsByApiKey } from '../../utils/common'

export default function KeyBenefits({ keyBenefits }) {
    if (!keyBenefits) {
        return "";
    }
    let summary = filterItemsByApiKey(keyBenefits, "summary", true)
    let benefits = filterItemsByApiKey(keyBenefits, "header_description_photo")
    return (
        <section class="white-section">
            <div class="container">
                {summary && <div class="content-center">
                    <h2 class="h2">{summary.title}</h2>
                    <div class="md-spacing"></div>
                </div>}

                <div class="grid-container-2">
                    {benefits.map(benefit => {
                        return (
                            <div class="flex-container start">
                                <div class="illustration benefit">
                                    {benefit.photo && <img src={benefit.photo.url} alt={benefit.photo.alt} />}
                                </div>
                                <div class="content-w-outline-icon">
                                    <div class="content-left center-m">
                                        <h6>{benefit.header}</h6>
                                        <div class="tn-spacing"></div>
                                        <p>
                                            <ReactMarkdown source={benefit.description} escapeHtml={false} />
                                        </p>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </section>
    )
}
