import React from 'react'
import ReactMarkdown from 'react-markdown'
import { resizeAndConvertImage } from "../../utils/common"
export default function Features({ features }) {
    if (!features) {
        return "";
    }

    return (
        <React.Fragment>
            {features.map(feature => {
                let isGrayBackground = feature.background === "Gray";
                return (
                    <section class={`white-section reducedbottom ${isGrayBackground && "gray"}`}>
                        <div class="container">
                            <div class="content-left">
                                <div class="flex-container space-between">
                                    <h2 class="h2 fixedwidth">{feature.header}</h2>
                                    <div class="_2cols-box full-w-t">
                                        <p class="p">
                                            <ReactMarkdown source={feature.description} escapeHtml={false}></ReactMarkdown>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="md-spacing"></div>
                            {feature.photo && <img src={`${feature.photo.url}${resizeAndConvertImage(1400)}`} alt={feature.photo.alt} />}
                        </div>
                    </section>
                )
            })}
        </React.Fragment>
    )
}
