import { Link } from "gatsby";
import { graphql } from 'gatsby'
import { HelmetDatoCms } from "gatsby-source-datocms";
import { renderTestimonialSection } from "../components/home/testimonialSection";
import { resetWebflow } from "../utils/common";
import React from "react";
import ReactMarkdown from "react-markdown";
import CommonQuestion from "../components/common-question";
import ClientLogo from "../components/integration/client-logo";
import Notification from "../components/header/notification";
import KeyBenefits from "../components/integration/keyBenefits";
import Slider from "../components/integration/slider";
import Features from "../components/integration/features";
import SupportedIntegration from "../components/integration/supported-integration";
import plusIcon from "../styles/images/plus-icon.svg";
import Layout from "../layouts";

export default ({
  data: {
    datoCmsIntegrationSuperPremium,
    allDatoCmsNotification: { edges: notifications }
  }
}) => {
  if (!datoCmsIntegrationSuperPremium) {
    return "";
  }

  let {
    pageTitle,
    pageDescription,
    platformImage,
    platformColor,
    testimonialSection,
    keyBenefits,
    slider,
    features,
    supportedIntegration,
    commonQuestions,
    clientLogoSection,
    seoMetaTags,
    notificationPage
  } = datoCmsIntegrationSuperPremium;

  resetWebflow("5ea948c53dd21d7ae45c0d69");

  const hasTestimonial = !!testimonialSection.length;
  let reduceBottom = !hasTestimonial;
  const testimonialSectionTmpl = hasTestimonial && renderTestimonialSection(testimonialSection);
  
  let clientLogo = clientLogoSection && clientLogoSection[0];

  return (
    <Layout>
      <HelmetDatoCms seo={seoMetaTags} />
      <Notification
        notifications={notifications}
        linkedPage={notificationPage}
      />
      <header class={`main-header about ${reduceBottom ? "reducedbottom" : "" }`}>
        <div class="container center">
          <div class="shadow-box integration-combine">
            <div class="platform-logo center nugit"></div>
            <div>
              <img src={plusIcon} class="image-3" />
            </div>
            {
              platformImage && <div class="platform-logo center"
                style={{
                  backgroundImage: `url(${platformImage.url})`,
                  backgroundColor: platformColor ? platformColor.hex : "white"
                }}>
              </div>
            }
            <div class="vertical-divider"></div>
          </div>
          <div class="md-spacing"></div>
          <h1 class="h-fixedwidth">{pageTitle}</h1>
          <div class="sm-spacing _30"></div>
          <div class="p center-align">
            <ReactMarkdown source={pageDescription} escapeHtml={false} />
          </div>
          <div class="sm-spacing _30"></div>
          <Link to="/request-demo" class="btn w-button tracking-demo-header-integration">
            Request Demo
          </Link>
          <div class="_2cols-box _56 center"></div>
        </div>
      </header>
      {testimonialSectionTmpl}
      <KeyBenefits keyBenefits={keyBenefits}></KeyBenefits>
      <Slider slider={slider} isGray={true}></Slider>
      <Features features={features}></Features>
      <SupportedIntegration integration={supportedIntegration}></SupportedIntegration>
      <section className="white-section gray">
        <div className="container">
          <CommonQuestion data={commonQuestions} />
        </div>
      </section>

      {clientLogo &&
        <div class="white-section">
          <ClientLogo clientLogo={clientLogo}></ClientLogo>
        </div>
      }
    </Layout>
  );
};

export const query = graphql`
  query IntegrationDetailSuperPremiumQuery($slug: String!) {
    datoCmsIntegrationSuperPremium(slug: {eq: $slug}) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      slug
      pageTitle
      platformImage {
        url
        alt
      }
      platformColor {
        hex
      }
      pageDescription      
      testimonialSection {
        ... on DatoCmsTestimonialDetail {
          text
          userAvatar {
            url
            alt
          }
          user
          userRole
          model {
            apiKey
          }
        }
      }
      keyBenefits {
        ... on DatoCmsSummary {
          title
          subTitle
          model {
            apiKey
          }
        }
        ... on DatoCmsHeaderDescriptionPhoto {
          header
          description
          photo {
            url
            title
            alt
          }
          model {
            apiKey
          }
        }
      }
      slider {
        ... on DatoCmsMainSummary {
          title
          description
          model {
            apiKey
          }
        }
        ... on DatoCmsHeaderDescriptionPhoto {
          header
          description
          photo {
            url
            title
            alt
          }
          model {
            apiKey
          }
        }
      }
      features {
        ... on DatoCmsPremiumFeature {
          header
          description
          photo {
            url
            title
            alt
          }
          background
          model {
            apiKey
          }
        }
      }
      supportedIntegration {
        ... on DatoCmsMainSummary {
          title
          description
          model {
            apiKey
          }
        }
        ... on DatoCmsNamePhotoAndBackground {
          name
          backgroundColor {
            hex
          }
          photo {
            url
            alt
          }
          model {
            apiKey
          }
        }
      }
      commonQuestions {
        ... on DatoCmsQuestion {
          id
          question
          answer
        }
      }
      clientLogoSection {
        ... on DatoCmsClientLogoWithHeading {
          sectionTitle
          logos {
            url
            alt
          }
        }
      }
      notificationPage {
        id
        page
      }
    }
    allDatoCmsNotification {
      edges {
        node {
          id
          content
          show
          weeks
          notificationPosition
          linkToPage {
            id
            page
          }
          backgroundColor {
            hex
          }
        }
      }
    }
  }
`;
